import { OpenAPI } from './api/core/OpenAPI';

export { ApiError } from './api/core/ApiError';
export { CancelablePromise, CancelError } from './api/core/CancelablePromise';
export { type OpenAPIConfig } from './api/core/OpenAPI';
export * from './api/schemas.gen'
export * from './api/services.gen'
export * from './api/types.gen'

let BASE: string;
if (process.env.NEXT_PUBLIC_BYOC_MODE_ENABLED !== 'true') {
  if (typeof window !== 'undefined') {
    BASE = localStorage.getItem('openapi.base') ?? process.env.NEXT_PUBLIC_REAL_BACKEND_URL ?? process.env.NEXT_PUBLIC_BACKEND_URL ?? ''
  } else {
    BASE = process.env.NEXT_PUBLIC_REAL_BACKEND_URL ?? process.env.NEXT_PUBLIC_BACKEND_URL ?? ''
  }
} else {
  // will be replaced by the real URL in the runtime by the proxy
  BASE = ''
}
OpenAPI.BASE = BASE;
OpenAPI.TOKEN = typeof window !== 'undefined' ? localStorage.getItem('openapi.token') ?? process.env.NEXT_PUBLIC_REAL_TOKEN ?? undefined : (process.env.NEXT_PUBLIC_REAL_TOKEN ?? undefined);
OpenAPI.WITH_CREDENTIALS = true;

export {
  OpenAPI
}
